body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Flaticon";
  src: url(../../static/media/Flaticon.237d24ec.eot);
  src: url(../../static/media/Flaticon.237d24ec.eot?#iefix) format("embedded-opentype"),
       url(../../static/media/Flaticon.a1d51308.woff) format("woff"),
       url(../../static/media/Flaticon.d5035b04.ttf) format("truetype"),
       url(../../static/media/Flaticon.f6b0dc33.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url(../../static/media/Flaticon.f6b0dc33.svg#Flaticon) format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-newspaper:before { content: "\F100"; }
.flaticon-goalie:before { content: "\F101"; }
.flaticon-football-player:before { content: "\F102"; }
.flaticon-soccer-field-1:before { content: "\F103"; }
.flaticon-water:before { content: "\F104"; }
.flaticon-goal-1:before { content: "\F105"; }
.flaticon-training-1:before { content: "\F106"; }
.flaticon-ticket:before { content: "\F107"; }
.flaticon-time:before { content: "\F108"; }
.flaticon-strategy-2:before { content: "\F109"; }
.flaticon-amonestation-1:before { content: "\F10A"; }
.flaticon-calendar:before { content: "\F10B"; }
.flaticon-training:before { content: "\F10C"; }
.flaticon-soccer-player:before { content: "\F10D"; }
.flaticon-soccer-1:before { content: "\F10E"; }
.flaticon-skills:before { content: "\F10F"; }
.flaticon-football-2:before { content: "\F110"; }
.flaticon-football-1:before { content: "\F111"; }
.flaticon-transfer:before { content: "\F112"; }
.flaticon-playoff:before { content: "\F113"; }
.flaticon-strategy-1:before { content: "\F114"; }
.flaticon-football-team:before { content: "\F115"; }
.flaticon-corner-1:before { content: "\F116"; }
.flaticon-change:before { content: "\F117"; }
.flaticon-football-trophy:before { content: "\F118"; }
.flaticon-gold-medal:before { content: "\F119"; }
.flaticon-football:before { content: "\F11A"; }
.flaticon-first-aid-kit:before { content: "\F11B"; }
.flaticon-glove:before { content: "\F11C"; }
.flaticon-cheering:before { content: "\F11D"; }
.flaticon-scoreboard:before { content: "\F11E"; }
.flaticon-trophy-1:before { content: "\F11F"; }
.flaticon-stopwatch:before { content: "\F120"; }
.flaticon-commentator:before { content: "\F121"; }
.flaticon-seats:before { content: "\F122"; }
.flaticon-trophy:before { content: "\F123"; }
.flaticon-soccer:before { content: "\F124"; }
.flaticon-television:before { content: "\F125"; }
.flaticon-smartphone:before { content: "\F126"; }
.flaticon-soccer-ball-1:before { content: "\F127"; }
.flaticon-football-jersey-1:before { content: "\F128"; }
.flaticon-football-jersey:before { content: "\F129"; }
.flaticon-strategy:before { content: "\F12A"; }
.flaticon-football-shoes:before { content: "\F12B"; }
.flaticon-goal:before { content: "\F12C"; }
.flaticon-corner:before { content: "\F12D"; }
.flaticon-referee:before { content: "\F12E"; }
.flaticon-soccer-ball:before { content: "\F12F"; }
.flaticon-amonestation:before { content: "\F130"; }
.flaticon-soccer-field:before { content: "\F131"; }

.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    -webkit-transition: opacity 30000ms linear 1050ms;
    transition: opacity 30000ms linear 1050ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    -webkit-transition: opacity 1050ms linear;
    transition: opacity 1050ms linear;
}

.wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.fab {
    position: relative;
}
.fabSecondary {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    -webkit-transition: -webkit-transform;
    transition: -webkit-transform;
    transition: transform;
    transition: transform, -webkit-transform;
}
.fabOpened .fabSecondary {
    -webkit-transform: translate(0, -30%);
            transform: translate(0, -30%);
}
.fab .chip {
    position: absolute;
}
.fab .tooltip {
    font-size: 12px;
    margin-right: 24px;
    position: absolute;
    top: 50%;
    right: 50px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
}
.fab.fabSecondary .tooltip {
    right: 42px;
}


@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-newspaper:before { content: "\f100"; }
.flaticon-goalie:before { content: "\f101"; }
.flaticon-football-player:before { content: "\f102"; }
.flaticon-soccer-field-1:before { content: "\f103"; }
.flaticon-water:before { content: "\f104"; }
.flaticon-goal-1:before { content: "\f105"; }
.flaticon-training-1:before { content: "\f106"; }
.flaticon-ticket:before { content: "\f107"; }
.flaticon-time:before { content: "\f108"; }
.flaticon-strategy-2:before { content: "\f109"; }
.flaticon-amonestation-1:before { content: "\f10a"; }
.flaticon-calendar:before { content: "\f10b"; }
.flaticon-training:before { content: "\f10c"; }
.flaticon-soccer-player:before { content: "\f10d"; }
.flaticon-soccer-1:before { content: "\f10e"; }
.flaticon-skills:before { content: "\f10f"; }
.flaticon-football-2:before { content: "\f110"; }
.flaticon-football-1:before { content: "\f111"; }
.flaticon-transfer:before { content: "\f112"; }
.flaticon-playoff:before { content: "\f113"; }
.flaticon-strategy-1:before { content: "\f114"; }
.flaticon-football-team:before { content: "\f115"; }
.flaticon-corner-1:before { content: "\f116"; }
.flaticon-change:before { content: "\f117"; }
.flaticon-football-trophy:before { content: "\f118"; }
.flaticon-gold-medal:before { content: "\f119"; }
.flaticon-football:before { content: "\f11a"; }
.flaticon-first-aid-kit:before { content: "\f11b"; }
.flaticon-glove:before { content: "\f11c"; }
.flaticon-cheering:before { content: "\f11d"; }
.flaticon-scoreboard:before { content: "\f11e"; }
.flaticon-trophy-1:before { content: "\f11f"; }
.flaticon-stopwatch:before { content: "\f120"; }
.flaticon-commentator:before { content: "\f121"; }
.flaticon-seats:before { content: "\f122"; }
.flaticon-trophy:before { content: "\f123"; }
.flaticon-soccer:before { content: "\f124"; }
.flaticon-television:before { content: "\f125"; }
.flaticon-smartphone:before { content: "\f126"; }
.flaticon-soccer-ball-1:before { content: "\f127"; }
.flaticon-football-jersey-1:before { content: "\f128"; }
.flaticon-football-jersey:before { content: "\f129"; }
.flaticon-strategy:before { content: "\f12a"; }
.flaticon-football-shoes:before { content: "\f12b"; }
.flaticon-goal:before { content: "\f12c"; }
.flaticon-corner:before { content: "\f12d"; }
.flaticon-referee:before { content: "\f12e"; }
.flaticon-soccer-ball:before { content: "\f12f"; }
.flaticon-amonestation:before { content: "\f130"; }
.flaticon-soccer-field:before { content: "\f131"; }

@import "css/flaticon.css";

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 30000ms linear 1050ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 1050ms linear;
}

.wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.fab {
    position: relative;
}
.fabSecondary {
    transform: translate(0, 0);
    transition: transform;
}
.fabOpened .fabSecondary {
    transform: translate(0, -30%);
}
.fab .chip {
    position: absolute;
}
.fab .tooltip {
    font-size: 12px;
    margin-right: 24px;
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translate(0, -50%);
}
.fab.fabSecondary .tooltip {
    right: 42px;
}